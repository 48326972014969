import { cFetch } from './apiUtils';
import { COMMON_ACTION_TYPES } from '../redux/commonActionTypes';
import config from '../config/config';
import getBrowserFingerprint from '../utils/deviceFingerprint';

export function sendOTP(contact) {
  return cFetch(`${config.API_URL}/a/auth/mobile/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ mobile: contact })
  });
}

export function login(mobile, otp) {
  let deviceId = localStorage.getItem('device_id');
  if (!deviceId) {
    deviceId = getBrowserFingerprint();
    localStorage.setItem('device_id', deviceId);
  }
  return cFetch(`${config.API_URL}/a/auth/token/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      mobile,
      token: otp,
      device_id: deviceId,
      device_type: 'WEB'
    })
  });
}

/**
 * This function is used to send signup request response and Login failed response
 * @param {Object} userdetail when user requested for Signup
 * @param {string} userDetail when user login failed ,basically contains contact no.
 * @param {string} signup_type indicates if type is signup request or login failed
 * returns response of api
 */
export function signup(userdetail, signup_type) {
  var bodyjson;

  if (signup_type == COMMON_ACTION_TYPES.SIGNUP_REQUEST) {
    bodyjson = {
      signup_type: signup_type,
      mobile: userdetail.Contact,
      name: userdetail.UserName ? userdetail.UserName : undefined,
      company_name: userdetail.CompanyName ? userdetail.CompanyName : undefined
    };
  } else {
    bodyjson = {
      signup_type: signup_type,
      mobile: userdetail
    };
  }
  return cFetch(`${config.API_URL}/api/signup/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyjson)
  });
}
